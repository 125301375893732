import { useEffect, useState } from 'react';

import { RemoteConfig } from '../remote-config/config-type';
import { fetchRemoteConfig } from '../remote-config';

const useRemoteConfig = () => {
    const [remoteConfig, setRemoteConfig] = useState<RemoteConfig | null>(null);

    useEffect(() => {
        if (remoteConfig) return;

        fetchRemoteConfig().then(setRemoteConfig);
    }, [remoteConfig]);

    return remoteConfig;
};

export { useRemoteConfig };
