import { PropsWithChildren, MouseEvent } from 'react';

import styles from './button.module.scss';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
    loading?: boolean;
    onClick?: () => void;
}

const Button = ({ children, loading, onClick }: Props) => {
    const handleClick = (ev: MouseEvent) => {
        ev.preventDefault();
        return onClick?.();
    };

    return (
        <button
            onClick={handleClick}
            className={classNames({
                [styles.button]: true,
                [styles.loading]: loading,
            })}
            disabled={loading}
        >
            {loading ? <span>Cargando...</span> : children}
        </button>
    );
};

export default Button;
