import { useRemoteConfig } from '../../../../hooks/remote-config';

import styles from './footer.module.scss';

const Footer = () => {
    const remoteConfig = useRemoteConfig();

    return (
        <footer className={styles.footer}>
            <span className={styles.divider} />
            <div className={styles.contactInfo}>
                <div className={styles.contactBlock}>
                    <label>Address</label>
                    <p>{remoteConfig?.landingPageContactInfo.address}</p>
                </div>
                <div className={styles.contactBlock}>
                    <label>Contact email</label>
                    <p>{remoteConfig?.landingPageContactInfo.email}</p>
                </div>
                <div className={styles.contactBlock}>
                    <label>Contact number</label>
                    <p>{remoteConfig?.landingPageContactInfo.phoneNumber}</p>
                </div>
            </div>
            <p style={{ textAlign: 'center', marginTop: 40 }}>
                © 2024 Cappacho. All rights reserved.
            </p>
        </footer>
    );
};

export default Footer;
