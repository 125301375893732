import { Route, Routes } from 'react-router';

import styles from './app.module.scss';
import HomePage from './pages/home/home.page';
import MainNav from './components/main-nav/main-nav';

function App() {
    return (
        <main id={styles.app}>
            <MainNav />
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </main>
    );
}

export default App;
