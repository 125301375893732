import {
    getRemoteConfig,
    fetchAndActivate,
    getAll,
} from 'firebase/remote-config';

import { app } from '../services/firebase';

import defaultConfig from './default.json';
import { RemoteConfig } from './config-type';

const fetchRemoteConfig = async (): Promise<RemoteConfig> => {
    const remoteConfig = getRemoteConfig(app);

    remoteConfig.settings = {
        minimumFetchIntervalMillis: 5000, // 5 seconds for testing only
        fetchTimeoutMillis: 5000, // 5 seconds for testing only
    };
    remoteConfig.defaultConfig = defaultConfig as any;

    await fetchAndActivate(remoteConfig);
    const allConfig = getAll(remoteConfig);

    const config: Record<string, any> = {};
    for (const entry of Object.entries(allConfig)) {
        const [key, { _value: value }] = entry as any;
        config[key] = JSON.parse(value);
    }

    return config as RemoteConfig;
};

export { fetchRemoteConfig };
